import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
// import { Outlet } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import {
  fetchAutoMedicines,
  fetchFunds,
  fetchManufacturers,
  fetchMedicines,
  useContact,
  useCreatePurchase,
  useFetchMedicineByExcel,
} from './api'
import { getColumns } from './contactColumns'
import {
  Button,
  ConfirmModal,
  DialogModal,
} from '../../../../components/common'
import PrimaryStockCreate from './create'
import StockEdit from './details'
import Icons from '../../../../components/common/icons'
import CustomisedListingHeader from '../../../../components/common/ListingTiles/customisedheader'
import { useSnackbar } from 'notistack'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import { useAuthStore } from '../../../../store/authStore'
// import { PrimaryFilterComponent } from '../../../../components/app/filterComponents/primaryFilter'
// import { GetFilterDetails } from './filterProperties'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'
interface Manufacturer {
  id: number
  name: string | undefined
}
interface Medicine {
  id?: any
  name?: any
  unit_of_packing?: any
  price?: any
  tax?: any
  stock?: any
  quantity?: any
}
const PruchaseMedicine = () => {
  // const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  // const [mergeIsActive, setMergeIsActve] = useState(false)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [editModalActive, setEditModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [cancelModalActive, setCancelModalActive] = useState(false)
  const [lowStocks, setLowStocks] = useState([])
  // const [manufacturerData, setManufacturerData] = useState<Manufacturer>()
  // const filterDetails = GetFilterDetails()
  const [cancelConfirmationActive, setCancelConfirmationActive] =
    useState(false)
  const { userData } = useAuthStore()
  const [customSearch, setCustomSearch] = useState('')
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
  const [customType, setCustomType] = useState('')
  const [med, setMed] = useState<Medicine[]>([])
  const [selectedMed, setSelectedMed] = useState<Medicine[]>([])
  const [customQuantity, setCustomQuantity] = useState('')
  const [funds, setFunds] = useState([])
  const [fundType, setFundType] = useState('')
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    ...(customType && userData?.primary_stock_threshold
      ? { primary_stock_threshold: userData?.primary_stock_threshold }
      : { primary_stock_threshold: 25 }),
    ...filters,
  }

  const { data, refetch, isFetching } = useContact(searchParams)
  const onSuccess = () => {
    // setMed([])
    // setFundType('')
    setSelectedMed([])

    setCustomSearch('select')
    setCustomQuantity('')
    setDialogModalActive(false)
    setSuccessModalActive(true)
  }
  const onFileSuccess = (res: any) => {
    console.log(res, 'test, test')
    const selected = [...selectedMed, ...res?.medicines]

    setSelectedMed(selected)
    console.log('selectedMed:', selectedMed, 'rmedicines:', res.medicines)
    // console.log('success')
  }
  const { mutate } = useCreatePurchase(onSuccess)
  const { mutate: fileUp } = useFetchMedicineByExcel(onFileSuccess)

  useEffect(() => {
    handleFetchManufacturers()
  }, [])
  const handleFetchManufacturers = () => {
    fetchManufacturers().then((res) => {
      setManufacturers(res?.manufacturers)
    })
    // setManufacturers(data)
  }

  useEffect(
    () => {
      handleFetchFunds()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const handleFetchFunds = () => {
    fetchFunds().then((res) => {
      setFunds(res?.funds)
    })
    // setManufacturers(data)
  }
  // console.log(funds)
  // useEffect(() => {
  //   setSelectedMed(data)
  // }, [data])
  console.log(data)
  const handleMedicines = () => {
    fetchMedicines({ manufacturer_id: customType })
      .then((res) => {
        const medWithQuantity = res?.medicines?.map((items: any) => {
          return {
            ...items,
            namewithqn: `${items?.name} ${items?.unit_of_packing}`,
          }
        })
        setMed(medWithQuantity)
        // console.log(res)

        // // console.log(res)
      })
      .finally(() => {
        fetchAutoMedicines({
          primary_stock_threshold: userData?.primary_stock_threshold || 25,
        }).then((res: any) => {
          console.log(res)
          // handleAutoIntending(res?.stocks)
          setLowStocks(res?.stocks)
        })
      })
    // setManufacturers(data)
  }
  useEffect(() => {
    handleAutoIntending(lowStocks, med)
  }, [med, lowStocks])

  const handleAutoIntending = (res: any, med: any) => {
    console.log(res)
    console.log(med)
    console.log(selectedMed)

    const newArray: Medicine[] = []

    med.forEach((obj1: any) => {
      const foundObj2 = res.find((obj2: any) => obj1.id === obj2.medicine_id)
      console.log(foundObj2)
      if (foundObj2) {
        newArray?.push({
          ...obj1,
          quantity: res.filter((obj2: any) => obj1.id === obj2.medicine_id)[0]
            .requested_quantity,
        })
        console.log(obj1)
      }
    })
    setSelectedMed(newArray)
  }
  useEffect(() => {
    setMed([])
    setCustomSearch('')
    if (customType && customType !== 'select') {
      handleMedicines()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customType])

  // console.log(customSearch)
  const generateTotal = () => {
    let amount = 0
    selectedMed.forEach((item: any) => {
      const total = item.quantity * item.price
      amount = amount + total
    })

    return amount
  }
  const generateName = (name: any) => {
    const manufacturerName = manufacturers.find((m) => m.id === Number(name))
    // console.log(manufacturerName)
    // setManufacturerData(manufacturerName)
    if (manufacturerName?.name) {
      return manufacturerName?.name
    } else {
      return ''
    }
  }
  // console.log(fundType)
  // const generateAmount = (customSearch: any, customAmount: any) => {
  //   const medicineDetails = med.find((m) => m.id === Number(customSearch))
  //   // setMed(medicineDetails ? [medicineDetails] : [])
  //   if (medicineDetails?.price) {
  //     if (medicineDetails?.tax) {
  //       if (medicineDetails?.stock && medicineDetails?.stock >= customAmount) {
  //         const total =
  //           customAmount * medicineDetails?.price
  //         return total
  //       } else {
  //         // setCustomQuantity('')
  //         alert`Quantity not available`
  //         return `Stock Low , ${medicineDetails?.stock} quantity available`
  //       }
  //     } else {
  //       return 'Tax not available'
  //     }
  //   } else {
  //     return 'Price not available'
  //   }
  // }

  useEffect(() => {
    refetch()
  }, [
    page,
    page_size,
    ordering,
    filters,
    refetch,
    customSearch,
    customType,
    customQuantity,
  ])
  // console.log(customSearch)

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: '',
    icon: '',
  }
  // const dashtiledata = [
  //   {
  //     id: 1,
  //     tiles: [
  //       {
  //         icon: 'user',
  //         label: 'Total Contacts',
  //         total: '$100',
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Active Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 2,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Inactive Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 3,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     tiles: [
  //       {
  //         label: 'Total Agents',
  //         icon: 'calendar',
  //         total: 100,
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         label: 'Active Agents',
  //         icon: 'badge-check',
  //         total: 100,
  //         count: 0,
  //         id: 2,
  //       },
  //       {
  //         label: 'Total Orders',
  //         icon: 'calendar',
  //         isCount: true,
  //         id: 3,
  //       },
  //     ],
  //   },
  // ]
  // const openDrawer = () => {
  //   setDrawerOpen(true)
  // }
  // const openPopup = () => {
  //   setOpenPopups(true)
  // }
  // const handleClose = () => {
  //   setDrawerOpen(false)
  //   setOpenPopups(false)
  // }
  // const handleRefresh = () => {
  //   refetch()
  // }
  // const filterDetails = GetFilterDetails()

  // const handleFilterChange = (dta: any, name: string, id: string) => {
  //   const currentParams = useContactFilterStore.getState()?.pageParams || {}

  //   const getDefaultFilters = () => ({
  //     ...currentParams.filters,
  //     [id]: dta?.id ?? undefined,
  //   })

  //   const getDefaultFilterProps = () => ({
  //     ...currentParams.filterProps,
  //     [name]: dta[name] ?? undefined,
  //   })
  //   const filters = getDefaultFilters()
  //   const filterProps = getDefaultFilterProps()

  //   setPageParams({
  //     ...currentParams,
  //     page: 1,
  //     filters,
  //     filterProps,
  //   })
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }
  // const handleMerge = () => {
  //   navigate(`${router_config.CONTACT_MERGE.path}`)
  //   setMergeIsActve(true)
  // }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])
  const onAction = (r: any, from: string) => {
    console.log(r, from)
    switch (from) {
      case 'edit':
        handleEditAction()
    }
  }
  const handleEditAction = () => {
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
  }
  const handleCancelAction = () => {
    setCancelModalActive(true)
  }
  const handleCancelClose = () => {
    setCancelModalActive(false)
  }
  const handleCancelConfirmationAction = () => {
    // setMed([])
    setFundType('')
    setSelectedMed([])
    setCustomSearch('select')
    setCustomQuantity('')
    handleCancelClose()
    setCancelConfirmationActive(true)
  }
  const handleCancelConfirmationClose = () => {
    setCancelConfirmationActive(false)
  }
  const handlSubmitEdit = () => {
    setEditModalActive(false)
    setSuccessModalActive(true)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  // if (mergeIsActive) {
  //   return <Outlet context={{ selectedRows, refetch }} />
  // }
  const activity = {
    hideExport: false,
  }

  const handleCreate = () => {
    const medicineDetails = med.find((m) => m.id === Number(customSearch))
    if (!customType) {
      enqueueSnackbar('Manufacturer not selected', {
        variant: 'error',
      })

      return
    }
    if (!medicineDetails) {
      enqueueSnackbar('Medicine not selected', {
        variant: 'error',
      })

      return
    }

    const quantity = Number(customQuantity)
    if (isNaN(quantity) || quantity <= 0) {
      enqueueSnackbar('Medicine quantity not selected', {
        variant: 'error',
      })
      return
    }
    if (!('name' in medicineDetails) || !medicineDetails?.name.trim()) {
      enqueueSnackbar('Medicine name is missing or empty in medicineDetails', {
        variant: 'error',
      })
      console.error('Medicine name is missing or empty in medicineDetails')
      return
    }

    const updatedMedicines = [
      ...selectedMed,
      {
        ...medicineDetails,
        quantity: customQuantity,
      },
    ]

    // Update the state with the new array of medicines
    setSelectedMed(updatedMedicines)
    setDialogModalActive(false)
    setCustomSearch('')
    setCustomQuantity('')
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }

  const generateMedicineInfo = () => {
    const medData: any = []
    if (selectedMed.length) {
      selectedMed.forEach((item: any) => {
        medData.push({ id: Number(item.id), quantity: Number(item.quantity) })
      })
    }
    return medData
  }
  // const generateAutoMedicineInfo = (data:any) => {
  //   const medData: any = []
  //   if (selectedMed.length) {
  //     selectedMed.forEach((item: any) => {
  //       medData.push({ id: Number(item.id), quantity: 0 })
  //     })
  //   }
  //   return medData
  // }
  const handleSubmit = () => {
    const data = {
      order: {
        manufacturer_id: Number(customType),
        fund_id: Number(fundType),
        medicines: generateMedicineInfo(),
      },
    }
    mutate(data)
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  // const handleFilterChange = (dta: any, name: string, id: string) => {
  //   console.log(dta, name, id)
  //   setCustomType(dta?.value)
  //   console.log(customType, 'hdgcjwhd')
  //   console.log(dta?.value)
  //   const currentParams = useContactFilterStore.getState()?.pageParams || {}

  //   const getDefaultFilters = () => ({
  //     ...currentParams.filters,
  //     [id]: dta?.id ?? undefined,
  //   })

  //   const getDefaultFilterProps = () => ({
  //     ...currentParams.filterProps,
  //     [name]: dta[name] ?? undefined,
  //   })
  //   const filters = getDefaultFilters()
  //   const filterProps = getDefaultFilterProps()

  //   setPageParams({
  //     ...currentParams,
  //     page: 1,
  //     institution_type: filterProps?.value,
  //     filters,
  //     filterProps,
  //     // ...(filterProps.value && ("institution_type" = filterProps.value)),
  //   })
  // }
  const handleUploadFile = (file: any) => {
    // console.log('file uplad done her kjwkfjhwekjhkwejf')
    console.log(customType)
    console.log(file)
    const fileData = {
      purchase_file: file,
      manufacturer_id: customType,
    }
    console.log(fileData)
    if (customType) {
      fileUp(fileData)
    } else {
      enqueueSnackbar('Select a manufacturer', { variant: 'error' })
    }
  }
  console.log(selectedMed, data)

  return (
    <div className="pt-[24px]">
      <CustomisedListingHeader
        data={basicData}
        onActionClick={handleCreate}
        actionTitle="Add Medicine"
        // onHandleExport={openPopup}
        actionProps={activity}
        setCustomSearch={setCustomSearch}
        customSearch={customSearch}
        setCustomType={setCustomType}
        setCustomQuantity={setCustomQuantity}
        manufactures={manufacturers}
        customQuantity={customQuantity}
        medicines={med}
        handleUploadFile={handleUploadFile}
      />
      {dialogModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <PrimaryStockCreate
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
          }
          onClose={handleClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {editModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <StockEdit
              handlSubmitEdit={handlSubmitEdit}
              handleEditClose={handleEditClose}
            />
          }
          onClose={handleEditClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <>
                <div className="font-medium text-primary leading-8 text-xl">
                  Order has been Successfully Placed
                </div>
                {/* <div className="text-m leading-normal text-SidenavFontColor">
                  Please check your mail for details
                </div> */}
              </>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      {cancelModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <>
                <div className="p-10">
                  <div className="font-medium leading-8 text-xl ">
                    Do you really want to cancel the order{' '}
                  </div>
                  <div className="flex justify-center my-5">
                    <Icons name="trash" className="w-[200px] h-[150px]" />
                  </div>
                  <div className="flex justify-center gap-4 mt-10">
                    <Button
                      label={'Yes'}
                      primary={false}
                      type="reset"
                      className="bg-AvailabilityMark/40 text-AvailabilityMark"
                      onClick={handleCancelConfirmationAction}
                    />
                    <Button
                      label={'No'}
                      primary={false}
                      type="reset"
                      className="bg-RemoveButton/40 text-RemoveButton"
                      onClick={handleCancelClose}
                    />
                  </div>
                </div>
              </>
            }
            onClose={handleCancelClose}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      {cancelConfirmationActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <>
                <div className="font-medium text-primary leading-8 text-xl">
                  Order Cancelled
                </div>
              </>
            }
            topIcon="check-circle"
            onClose={handleCancelConfirmationClose}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      <div className="">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          // search
          handleColumnSort={handleSort}
          height={calcWindowHeight(538)}
          data={selectedMed ?? []}
          handleResetColumns={() => handleResetColums()}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
      <div className="flex flex-col xl:flex-row justify-between pt-5 xl:items-center gap-2">
        <div className="flex justify-start">
          <div className="2xl:text-m lg:text-xs text-[10px] pe-3 2xl:pe-5 font-normal leading-normal items-center 2xl:h-[58px] h-[48px]	text-SidenavFontColor border-r-2  border-bgGreylite">
            <div>Manufacturer </div>
            <div className="font-medium">
              {customType &&
                customType !== 'select' &&
                generateName(customType)}
            </div>
          </div>
          <div className="2xl:text-m lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal items-center 2xl:h-[58px] h-[48px]	text-SidenavFontColor border-r-2  border-bgGreylite">
            <div>Total Items</div>
            <div className="font-medium">{selectedMed?.length} items</div>
          </div>
          <div className="2xl:text-m lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal items-center 2xl:h-[58px] h-[48px]	text-SidenavFontColor border-r-2  border-bgGreylite">
            <div>Total Amount</div>
            <div className="font-medium">{generateTotal()}</div>
          </div>
          <div className="2xl:text-m lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal items-center 2xl:h-[58px] h-[48px]	text-SidenavFontColor border-r-2  border-bgGreylite">
            <div>Purchased On</div>
            <div className="font-medium">{moment().format('DD MMM YYYY')}</div>
          </div>
        </div>
        <div className="flex gap-4 justify-center xl:items-center">
          <div className="flex gap-4 justify-end h-full xl:items-center">
            {/* <div className="border rounded-lg px-3 py-1 flex items-center h-auto table-header-input w-[155px]">
              <img
                src="/search.png"
                alt="search here"
                className="w-auto h-[15px]"
              />
              <input
                type="text"
                placeholder="search"
                name="search"
                className=" px-2"
                onChange={(e) => setCustomSearch(e.target.value)}
              />
            </div> */}

            <div className="table-header-select border rounded-lg h-auto w-[150px] 2xl:w-[220px]">
              <select
                name="select_fund"
                id="fund"
                className="py-1 px-2 bg-white w-full 2xl:h-[43px] h-[44px]"
                onChange={(e) => setFundType(e.target.value)}
              >
                {' '}
                <option value="select">Select Fund</option>
                {funds.map((item: any, key: number) => (
                  <option key={key} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <Button
              label={'Purchase  Medicine '}
              primary={true}
              type="reset"
              className="2xl:h-[44px] h-[44px] w-[155px] min-w-fit"
              onClick={handleSubmit}
            />
            <Icons
              name="delete"
              className="border-2 border-RemoveButton/15 rounded-[10px] p-2 2xl:h-[44px] h-[44px] items-center"
              onClick={handleCancelAction}
            />
          </div>
        </div>
      </div>{' '}
    </div>
  )
}

export default PruchaseMedicine
