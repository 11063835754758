// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../components/common'
import CommonForm from '../pharmacist_form'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
type ManageStocProps = {
  handlSubmit: (payload: any) => void
  handleClose: () => void
  loading: any
}
const StockCreate: React.FC<ManageStocProps> = ({
  handlSubmit,
  handleClose,
  loading,
}) => {
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})
  const onSubmit = (payload: CreateSchema) => {
    // setSuccessModalActive(true)
    handlSubmit(payload)
    // console.log(payload)
  }
  const handleDownload = () => {
    const excelFilePath = '/Stock Add Excel sheet format.xlsx'
    const link = document.createElement('a')
    link.download = 'Stock Add Excel sheet format.xlsx'
    link.href = excelFilePath
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          <u>Add Stock</u>
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <FormProvider {...methods}>
                <CommonForm edit={true} />
              </FormProvider>
            </div>
            <div
              className="cursor-pointer text-m font-normal flex items-end pb-2.5	text-AvailabilityMark underline"
              onClick={handleDownload}
            >
              Download Excel Format
            </div>
          </div>
          <div className="flex gap-8 justify-center my-3">
            <Button onClick={handleClose} label={'Cancel'} outlined={true} />
            <Button
              // onClick={methods.handleSubmit}
              type="submit"
              label={'Submit'}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default StockCreate
