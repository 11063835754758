import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  medicine_name: z
    .string({ invalid_type_error: 'Medicine Name is required.' })
    .min(1, { message: 'Medicine Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  unit_of_packing: z
    .string({ invalid_type_error: 'Unit of Packing is required.' })
    .min(1, { message: 'Unit of Packing is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  batch_number: z.string().nullable(),
  primary_stock: z.string().nullable(),
  // primary_stock: z
  //   .string()
  //   .nullable()
  //   .transform((value) => (value === null ? '' : value))
  //   .refine(
  //     (value) => {
  //       return (
  //         (/^\d+(?:\.\d{0,10})?$/.test(value) &&
  //           value.length <= 10 &&
  //           parseFloat(value) !== 0) ||
  //         value === ''
  //       )
  //     },
  //     {
  //       message: '',
  //     }
  //   ),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
