import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import {
  getErrorMessage,
  getSortedColumnName,
} from '../../../../utilities/parsers'
import { deleteStockPrimary, useContact } from './api'
import { getColumns } from './contactColumns'
import {
  Button,
  ConfirmModal,
  DialogModal,
} from '../../../../components/common'
import PrimaryStockCreate from './create'
import StockEdit from './details'
import { useCreateStock, useCreateStockAddNew } from './create/api'
import { useSnackbar } from 'notistack'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
// import { Button } from '@mui/material'
import Icons from '../../../../components/common/icons'
import PrimaryStockCreateForm from './add new'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const PrimaryStockMain = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { setActiveTab } = useOutletContext<any>()
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [dialogModalActiveAdd, setDialogModalActiveAdd] = useState(false)
  const [editModalActive, setEditModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [deleteModalActive, setDeleteModalActive] = useState(false)
  const [deleteId, setDeleteId] = useState<any>()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState({})

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const onSuccess = () => {
    setDialogModalActive(false)
    setDialogModalActiveAdd(false)
    refetch()
    setSuccessModalActive(true)
  }
  // const onDeleteSuccess = () => {

  // }
  const { mutate } = useCreateStock(onSuccess)
  const { mutate: mutateNewStock } = useCreateStockAddNew(onSuccess)
  // const {mutate:StockDelete} = useDeleteStock(onDeleteSuccess)
  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  useEffect(
    () => {
      setActiveTab('STOCK_PRIMARY')
      refetch()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, page_size, search, ordering, filters, refetch]
  )

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const onAction = (r: any, from: string) => {
    console.log(r, from)
    switch (from) {
      case 'edit':
        handleEditAction(r)
        break
      case 'delete':
        setDeleteConfirm(true)
        setDeleteId(r)
        break
    }
  }
  const handleDeleteAction = (r: any) => {
    setDeleteConfirm(false)
    if (r.id) {
      deleteStockPrimary(r.id, 'primary_stock')
        .then(() => {
          setDeleteModalActive(true)
          refetch()
        })
        .catch((err: any) => {
          console.log(err)
          enqueueSnackbar(getErrorMessage(err?.response?.data?.message), {
            variant: 'error',
          })
        })
        .finally(() => {
          refetch()
        })
    }
  }
  const handleDeleteActionClose = () => {
    setDeleteModalActive(false)
  }

  const handleEditAction = (r: any) => {
    setEditData(r)
    setEdit(true)
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
    setEdit(false)
    refetch()
  }
  const handlSubmitEdit = () => {
    setEditModalActive(false)
    setSuccessModalActive(true)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      // onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        // onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }

  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handleCreateAdd = () => {
    setDialogModalActiveAdd(true)
  }
  const handleCloseAdd = () => {
    setDialogModalActiveAdd(false)
  }
  const handleSubmit = (payload: any) => {
    // setDialogModalActive(false)
    // setSuccessModalActive(true)
    const data = {
      institution_stock_import: {
        ...payload,
      },
    }
    // console.log(data)
    mutate(data)
  }
  const handleSubmitAddNew = (payload: any) => {
    mutateNewStock(payload)
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
    refetch()
  }
  const handleCancelClose = () => {
    setDeleteConfirm(false)
    setDeleteId(null)
  }
  return (
    <div className="">
      {/* <ListingHeader
        data={basicData}
        onActionClick={handleCreate}
        actionTitle="Add Stock"
        // onHandleExport={openPopup}
        actionProps={activity}
        setCustomSearch={setCustomSearch}
      /> */}
      {dialogModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <PrimaryStockCreate
              handleSubmit={(payload) => handleSubmit(payload)}
              handleClose={handleClose}
            />
          }
          onClose={handleClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {dialogModalActiveAdd && (
        <DialogModal
          isOpen={true}
          small={false}
          // className='w-[500px]'
          body={
            <PrimaryStockCreateForm
              handleSubmit={(payload) => handleSubmitAddNew(payload)}
              handleCloseAdd={handleCloseAdd}
            />
          }
          onClose={handleCloseAdd}
          title={'Add Stock'}
        />
      )}
      {editModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <StockEdit
              handlSubmitEdit={handlSubmitEdit}
              handleEditClose={handleEditClose}
              data={editData}
            />
          }
          onClose={handleEditClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <>
          {!edit ? (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Stock Added Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          ) : (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Stock Edited Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </>
      )}
      {deleteConfirm && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <>
                <div className="p-10">
                  <div className="font-medium leading-8 text-xl ">
                    Do you really want to delete this stock?{' '}
                  </div>
                  <div className="flex justify-center my-5">
                    <Icons name="trash" className="w-[200px] h-[150px]" />
                  </div>
                  <div className="flex justify-center gap-4 mt-10">
                    <Button
                      label={'Yes'}
                      primary={false}
                      type="reset"
                      className="bg-AvailabilityMark/40 text-AvailabilityMark"
                      onClick={() => handleDeleteAction(deleteId)}
                    />
                    <Button
                      label={'No'}
                      primary={false}
                      type="reset"
                      className="bg-RemoveButton/40 text-RemoveButton"
                      onClick={handleCancelClose}
                    />
                  </div>
                </div>
              </>
            }
            onClose={handleCancelClose}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      {deleteModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Stock Deleted Successfully
              </div>
            }
            topIcon="check-circle"
            onClose={handleDeleteActionClose}
            // title={'Add New Pharmacist'}
          />
        </>
      )}

      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(457)}
          data={data?.stocks ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          tableHeaderActions={
            <>
              <Button
                onClick={handleCreateAdd}
                label={'Add Stock'}
                icon="plus"
                size="xs"
              />
              <Button
                onClick={handleCreate}
                label={'Excel Upload'}
                icon="plus"
                size="xs"
              />
            </>
          }
          actionProps={[
            {
              title: 'View',
              action: (rowData) => onAction(rowData, 'edit'),
              icon: <Icons name="edit" />,
              toolTip: 'Edit',
            },
            {
              title: 'View',
              action: (rowData) => onAction(rowData, 'delete'),
              icon: <Icons name="delete" />,
              toolTip: 'Delete',
            },
          ]}
          selectedRowActions={[
            {
              actionTitle: 'Merge',
              action: () => handleMerge(),
              customHide: '>2',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default PrimaryStockMain
