import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
import ListingHeader from '../../../components/common/ListingTiles'
import { router_config } from '../../../configs/route.config'
import { useContactFilterStore } from '../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../utilities/generators'
import { getSortedColumnName } from '../../../utilities/parsers'
import { useManufacturerStock } from './api'
import { getColumns } from './contactColumns'
import { ConfirmModal, DialogModal } from '../../../components/common'
import StockCreate from './create'
import Icons from '../../../components/common/icons'
import StockEdit from './details'
import { useCreateStocks, useDeleteFund } from './create/api'
import { calcWindowHeight } from '../../../utilities/calcHeight'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const ManufactureStockMain = () => {
  const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [editModalActive, setEditModalActive] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [deleteModalActive, setDeleteModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [loading, setLoading] = useState(false)
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const [customSearch, setCustomSearch] = useState('')
  const onSuccess = () => {
    setLoading(false)
    if (!deleteStatus) {
      handleClose()
      setSuccessModalActive(true)
      refetch()
    } else {
      setDeleteModalActive(true)
      refetch()
    }
  }

  const { mutate: createStocksMutate } = useCreateStocks(onSuccess)
  const { mutate: deleteFundMutate } = useDeleteFund(onSuccess)

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    // ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useManufacturerStock(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, customSearch, search, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: 'Stock',
    icon: 'stock',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])
  const handleEditAction = (r: any) => {
    setEditData(r)
    setEdit(true)
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
    setEdit(false)
  }
  const handleDeleteAction = (r: any) => {
    console.log(r)
    deleteFundMutate(r.id)
    setDeleteStatus(true)
  }
  const handleDeleteActionClose = () => {
    setDeleteModalActive(false)
    setDeleteStatus(false)
  }

  const onAction = (r: any, from: string) => {
    if (r.id) {
      switch (from) {
        case 'edit':
          handleEditAction(r)
          break
        case 'delete':
          handleDeleteAction(r)
          break
      }
    }
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
    loadingData: data?.last_import_status,
  }
  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handlSubmit = (payload: any) => {
    setLoading(true)
    const data = {
      manufacturer_stock_import: {
        ...payload,
      },
    }
    createStocksMutate(data)
  }
  const handlSubmitEdit = () => {
    setEditModalActive(false)
    refetch()
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    handleClose()
    handleEditClose()
  }
  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          onActionClick={handleCreate}
          actionTitle="Add Stock"
          // onHandleExport={openPopup}
          actionProps={activity}
          setCustomSearch={setCustomSearch}
        />
        {dialogModalActive && (
          <DialogModal
            isOpen={true}
            body={
              <StockCreate
                handlSubmit={(payload) => handlSubmit(payload)}
                handleClose={handleClose}
                loading={loading}
              />
            }
            onClose={handleClose}
            // title={'Add New Pharmacist'}
          />
        )}
        {editModalActive && (
          <DialogModal
            isOpen={true}
            body={
              <StockEdit
                handlSubmitEdit={handlSubmitEdit}
                handleEditClose={handleEditClose}
                data={editData}
              />
            }
            onClose={handleEditClose}
            // title={'Add New Pharmacist'}
          />
        )}
        {successModalActive && (
          <>
            {!edit ? (
              <ConfirmModal
                isOpen={true}
                body={
                  <div className="font-medium text-primary leading-8 text-xl">
                    Stock import is inprogress, please check after some time
                  </div>
                }
                topIcon="check-circle"
                onClose={handleCloseModal}
                // title={'Add New Pharmacist'}
              />
            ) : (
              <ConfirmModal
                isOpen={true}
                body={
                  <div className="font-medium text-primary leading-8 text-xl">
                    Stock Edited Successfully
                  </div>
                }
                topIcon="check-circle"
                onClose={handleCloseModal}
                // title={'Add New Pharmacist'}
              />
            )}
          </>
        )}
        {deleteModalActive && (
          <>
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Stock Deleted Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleDeleteActionClose}
            />
          </>
        )}
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
        {/* <div className=" px-[24px] py-[24px]">
          <QbsTable
            // data={[]}
            data={data?.stocks ?? []}
            dataRowKey="id"
            // toolbar={true}
            selectedRows={selectedRows}
            // selection={true}
            // searchValue={searchKey}
            // handleColumnToggle={handleColumnToggle}
            // search={true}
            isLoading={isFetching}
            sortType={pageParams.sortType}
            sortColumn={pageParams.sortColumn}
            handleColumnSort={handleSort}
            columns={columns}
            tableBodyHeight="calc(100vh - 200px)"
            pagination={true}
            handleResetColumns={() => handleResetColums()}
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            height={100}
            minHeight={100}
            // tableHeaderActions={}
            // toolbar={false}
            headerHeight={60}
            // classes={["font-size :19px"]}
            actionProps={[
              {
                icon: <Icons name="eye" />,
                action: (row) => onAction(row, ''),
                title: 'view',
              },
            ]}
            // primaryFilter={
            //   <PrimaryFilterComponent
            //     filterParams={filterDetails}
            //     handleFilterChange={handleFilterChange}
            //     filterProps={filterProps}
            //     advanceFilter={advanceFilter}
            //     handleFilterDateChange={handleFilterDateChange}
            //     isPrimary={true}
            //     setAdvanceFilter={setAdvanceFilter}
            //   />
            // }
            // advancefilter={
            //   showDetail && (
            //     <PrimaryFilterComponent
            //       filterParams={filterDetails}
            //       handleFilterDateChange={handleFilterDateChange}
            //       handleFilterChange={handleFilterChange}
            //       filterProps={filterProps}
            //       isPrimary={false}
            //       advanceFilter={advanceFilter}
            //     />
            //   )
            // }
            selectedRowActions={[
              {
                actionTitle: 'Merge',
                action: () => handleMerge(),
                customHide: '>2',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
            // tableHeaderActions={
            //   <div className="flex gap-2">
            //     {handleSHowHide() && (
            //       <Button
            //         onClick={() => {
            //           setShowDetail(!showDetail)
            //         }}
            //         label={showDetail ? 'Hide' : 'Show'}
            //         className="se"
            //         primary
            //       />
            //     )}
            //   </div>
            // }
          />
        </div> */}
        <div className=" px-[24px] py-[24px]">
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            search
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.stocks ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            actionProps={[
              {
                title: 'View',
                action: (rowData) => onAction(rowData, 'edit'),
                icon: <Icons name="edit" />,
                toolTip: 'Edit',
              },
              {
                title: 'Delete',
                action: (rowData) => onAction(rowData, 'delete'),
                icon: <Icons name="delete" />,
                toolTip: 'Delete',
              },
            ]}
            selectedRowActions={[
              {
                actionTitle: 'Merge',
                action: () => handleMerge(),
                customHide: '>2',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
      </div>
    </div>
  )
}

export default ManufactureStockMain
