import { useSnackbar } from 'notistack'
import { getData, updateJsonData } from '../apis/api.helpers'
import apiUrl from '../apis/api.url'
import { useQuery } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

const fetchData = async () => {
  const url = apiUrl.PROFILE_URL
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useFetch = () => {
  return useQuery(['contact_list'], () => fetchData(), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const createFund = (input: any) => {
  return updateJsonData(apiUrl.RESET_PASSWORD_URL, input)
}

export const useResetPassword = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createFund, {
    onSuccess: (res: any) => {
      console.log("Response on success:", res);
      if (res?.data?.message) {
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
      handleSubmission(res.data);
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong. Please try again.";
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });
};

