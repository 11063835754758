import { z } from 'zod'
import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  id: z.number().optional().nullable(),
  name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  patient_id: z.number().optional(),
  gender: z
    .string({ invalid_type_error: 'Gender is required.' })
    .min(1, { message: 'Gender is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  // date_of_birth: z
  //   .union([
  //     z.date({ invalid_type_error: 'Dob is required.' }),
  //     z.string({ invalid_type_error: 'Dob is required.' }),
  //   ])
  //   .refine((date) => date !== undefined || date !== null || date !== '', {
  //     message: 'Date of Birth is required.',
  //   }),
  district_name: z
    .string({ invalid_type_error: 'District is required.' })
    .min(1, { message: 'District is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  district_id: z.number().nullable(),
  address: z
    .string({ invalid_type_error: 'Address is required.' })
    .min(1, { message: 'Address is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  // specialization: z
  //   .string({ invalid_type_error: 'Specialisation is required.' })
  //   .min(1, { message: 'Specialisation is required.' })
  //   .refine(noLeadingSpaces, {
  //     message: 'Leading spaces are not allowed',
  //   }),
  // doctor: z
  //   .string({ invalid_type_error: 'Doctor is required.' })
  //   .min(1, { message: 'Doctor is required.' })
  //   .refine(noLeadingSpaces, {
  //     message: 'Leading spaces are not allowed',
  //   }),
  // doctor_id: z.number().optional().nullable(),
  phone: z
    .string()
    .refine((value) => value === '' || /^(\+?\d{10})$/.test(value), {
      message: 'Invalid mobile number.',
    })
    .nullable(),
  age: z
    .any()
    .refine((value) => value === '' || /^(\+?\d{1,3})$/.test(value), {
      message: 'Invalid Age.',
    })
    .nullable(),
  aadhar_number: z
    .string()
    .refine((value) => value === '' || /^(\+?\d{12})$/.test(value), {
      message: 'Invalid Aadhar number.',
    })
    .nullable(),
  remarks: z.string().optional().nullable(),
  update: z.boolean().optional().nullable(),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
