import { Button } from '../../../../../components/common'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import CommonForm from '../add new form'
import { useEffect, useRef, useState } from 'react'
import { useManufacturerData, useMedicineData } from '../api'
import { useSnackbar } from 'notistack'
import { FieldErrors, FieldError } from 'react-hook-form'

type ManagePharmacistProps = {
  handleSubmit: (payload: any) => void
  handleCloseAdd: () => void
}

const PrimaryStockCreateForm: React.FC<ManagePharmacistProps> = ({
  handleSubmit,
  handleCloseAdd,
}) => {
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'institution_stocks',
  })
  const [manufacturer, setManufacturer] = useState<any>()
  const [medicine, setMedicine] = useState<any>('')
  const lastManufacturerRef = useRef('');

  // Provide default values for the form
  const defaultValues = {
    manufacturer_name: '',
    medicine_name: '',
    unit_of_packing: '',
    batch_number: '',
    quantity: 0,
    unit: '',
    invoice_no: '',
    invoice_date: new Date(),
    speciality: '',
    fund_id: '',
  }

  const handleErrors = (errors: FieldErrors<CreateSchema>) => {
    if (errors) {
      Object.keys(errors).forEach((field) => {
        const fieldKey = field as keyof typeof errors
        const fieldErrors = errors[fieldKey]

        if (Array.isArray(fieldErrors)) {
          fieldErrors.forEach((error, index) => {
            Object.values(error).forEach((subError) => {
              const typedError = subError as FieldError
              enqueueSnackbar(`${fieldKey}[${index}]: ${typedError.message}`, {
                variant: 'error',
              })
            })
          })
        } else {
          enqueueSnackbar(`${fieldKey}: ${fieldErrors?.message}`, {
            variant: 'error',
          })
        }
      })
    }
  }

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultValues)
    }
  }, [append, fields.length])

  const onSubmit = (payload: CreateSchema) => {
    const watchedValues = methods.watch()
    console.log(payload)

    const updatedPayload = {
      ...payload,
      institution_stocks: payload.institution_stocks.map((stock, index) => {
        const fundIdKey = `fund_id-${index}` as keyof typeof watchedValues
        const fundId = watchedValues[fundIdKey]

        return {
          ...stock,
          fund_id: fundId,
        }
      }),
    }

    handleSubmit(updatedPayload)
  }

  const clearForm = (
    methods: any, 
    index: number, 
    defaultValues: Record<string, any>
  ) => {
    const updatedFields = methods.getValues("institution_stocks").map(
      (stock: any, idx: number) => (idx === index ? defaultValues : stock)
    );
    methods.setValue("institution_stocks", updatedFields, {
      shouldDirty: true, // Mark form as dirty to trigger revalidation if necessary
    });
  };
  

  const { data: medData, refetch: medicineRefetch } = useMedicineData(
    manufacturer,
    medicine
  )

  const { data: manuData, refetch: manufacturerRefetch } = useManufacturerData()

  useEffect(() => {
    manufacturerRefetch
  }, [])

  // Watch institution_stocks and refetch medicine data when needed
  // useEffect(() => {
  //   const subscription = methods.watch((value) => {
  //     const institution_stocks = value?.institution_stocks

  //     if (institution_stocks && institution_stocks.length > 0) {
  //       institution_stocks.forEach((stock) => {
  //         const manufacturerName = stock?.manufacturer_name
  //         const medicineName = stock?.medicine_name

  //         // Update manufacturer or medicine and trigger refetch for each row
  //         if (manufacturerName) {
  //           setManufacturer(manufacturerName)
  //           setMedicine('')
  //           medicineRefetch()
  //         }
  //         if (medicineName && medicineName !== medicine) {
  //           setMedicine(medicineName)
  //           medicineRefetch()
  //         }
  //       })
  //     }
  //   })

  //   return () => subscription.unsubscribe()
  // }, [methods, manufacturer, medicine, medicineRefetch])

  useEffect(() => {

    const subscription = methods.watch((value) => {
      const institution_stocks = value?.institution_stocks;

      if (institution_stocks && institution_stocks.length > 0) {
        institution_stocks.forEach((stock) => {
          const manufacturerName = stock?.manufacturer_name;

          if (manufacturerName && manufacturerName !== lastManufacturerRef.current) {
            setManufacturer(manufacturerName);
            setMedicine('');

            lastManufacturerRef.current = manufacturerName;
          }
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [methods, medicineRefetch]);

  useEffect(() => {
    if (manufacturer || medicine) {
      medicineRefetch()
    }
  }, [manufacturer, medicine])

  console.log(methods.watch())
  console.log(methods.formState.errors)

  return (
    <div className="my-5 mx-8">
      {manuData && (
        <div>

          {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
          <FormProvider {...methods}>
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="my-5 border border-gray-300 p-4 rounded "
              >
                <div className="grid md:grid-cols-5 md:gap-8 sm:grid-cols-1 sm:gap-4 my-3">
                  <CommonForm
                    edit={true}
                    index={index}
                    medData={medData}
                    manuData={manuData}
                  />
                  <div className="flex justify-start mt-2 gap-3">
                    <Button
                      type="button"
                      className="w-[100px] bg-black text-red-600"
                      primary={false}
                      label={'REMOVE'}
                      onClick={() => remove(index)}
                    />
                    <Button
                      type="button"
                      className="w-[100px] bg-[#B7B7B7] text-white"
                      primary={false}
                      label={'CLEAR'}
                      onClick={() => clearForm(methods, index, defaultValues)}
                    />

                  </div>
                </div>
              </div>
            ))}
          </FormProvider>

          <div className="flex gap-8 mt-3 justify-center">
            <Button
              type="button"
              label={'+ Add New'}
              onClick={() => {
                setMedicine('')
                append(defaultValues)
              }}
            />

            <Button
              onClick={() => methods.reset({})}
              type="button"
              label={'Clear All'}
              outlined={true}
            />

            <Button onClick={handleCloseAdd} label={'Cancel'} type='button' outlined={true} />
            <Button type="button" label={'Submit'} onClick={methods.handleSubmit(onSubmit, handleErrors)} />
          </div>
          {/* </form> */}

        </div>
      )}
    </div>
  )
}

export default PrimaryStockCreateForm
