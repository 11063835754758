// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import React from 'react'

import { Avatar } from '@mui/material'
// import ThemeSwitcher from '../components/common/themeswitcher'
import { useAuthStore } from '../store/authStore'
import { useClearFilter } from '../store/filterSore/clearStore'
import Icons from '../components/common/icons'
import { Button, DialogModal } from '../components/common'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import GeneralInfoForm from './form'
import { useFetch, useResetPassword } from './api'
import GeneralResetForm from './form_reset'
import { useSnackbar } from 'notistack'
function stringToColor(string: string) {
  // console.log(name)
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value?.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

// console.log(UserDataProps)
// export function stringAvatar(name: string) {
//   console.log(name)
//   return {
//     sx: {
//       bgcolor: stringToColor(name ?? 'Super Admin'),
//     },
//     children: `${name?.split(' ')[0]?.[0]}${name?.split(' ')[1]?.[0]}`,
//   }
// }
export function stringAvatar(name: string) {
  console.log(name)

  const nameParts = name?.split(' ')

  // If the name has multiple words, use the first character of each word for initials
  // Otherwise, use the first character of the first word only
  const children =
    nameParts && nameParts.length > 0
      ? `${nameParts[0]?.[0]}${nameParts.length > 1 ? nameParts[1]?.[0] : ''}`
      : ''

  return {
    sx: {
      bgcolor: stringToColor(name ?? 'Super Admin'),
    },
    children,
  }
}

// type ManageHeaderProps = {
//   handleSideBar: () => void
// }
export default function Header(props: any) {
  //   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  //   const open = Boolean(anchorEl)
  //   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget)
  //   }
  //   const handleClose = () => {
  //     setAnchorEl(null)
  //   }

  const { clearAuthenticated, userData } = useAuthStore()
  const [profile, setProfile] = useState(false)
  const [reset, setReset] = useState(false)

  const { data } = useFetch()
  const onSuccess = () => {
    setReset(false)
    methodsReset.reset({ current_password: '', new_password: '', password_confirmation: '' }); 
  }
  const { mutate } = useResetPassword(onSuccess)
  const { enqueueSnackbar } = useSnackbar();

  // const { clearAuthenticated } = useAuthStore()
  // const { setAuthenticated, clearAuthenticated } = useAuthStore<any>()
  const handleClear = useClearFilter()
  const handleLogout = () => {
    clearAuthenticated()
    handleClear()
  }
  const handleProfile = () => {
    setProfile(true)
  }
  const handleReset = () => {
    setProfile(false)
    setReset(true)
  }
  const handleCloseReset = () => {
    setReset(false)
  }
  const handleClose = () => {
    setProfile(false)
  }

  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: `${data?.name}`,
      role: `${data?.role}`,
      email: `${data?.email}`,
      phone: `${data?.phone_number}`,
    },
  })

  const methodsReset = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleSubmit = () => {
    const data = methodsReset.getValues();
  
    const { current_password, password, password_confirmation } = data;
    if (!current_password || !password || !password_confirmation) {
      enqueueSnackbar("All fields are required.", { variant: "error" });
      return;
    }
  
    if (password !== password_confirmation) {
      enqueueSnackbar("Passwords do not match.", { variant: "error" });
      return;
    }
    mutate(data);
  };
  

  useEffect(() => {
    methods.reset({
      ...data,
    })
  }, [data])
  return (
    <>
      <header
        className={`${props.auth !== true ? ' border-b border-divider ' : ''
          } dark:bg-gray-700 drop-shadow-md md:drop-shadow-none dark:text-white bg-white text-gray-700 flex justify-between items-center px-[15px] py-[12px] z-10 h-24`}
      >
        {props.auth !== true ? (
          <>
            <div className="px-2 md:hidden block">
              <Icons
                name="sidebar"
                className="cursor-pointer"
                onClick={props.handleSideBar}
              />{' '}
            </div>
            <div className="flex md:h-16 md:w-full w-[15rem] relative justify-between items-center pr-3">
              <img src="/header-logo.png" alt="ayush logo" />
              <div
                className="w-18 h-9 flex items-center justify-center cursor-pointer"
                onClick={() => handleProfile()}
              >
                {userData?.role === 'Super Admin' ? (
                  <Avatar
                    {...stringAvatar(userData?.role as string)}
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: '#45B755',
                      fontSize: '19px',
                      text: 'fff',
                      fontWeight: 'bold',
                    }}
                  // onClick={(e) => {
                  //   handleClick(e)
                  // }}
                  />
                ) : (
                  <>
                    {userData?.image ? (
                      <Avatar
                        alt="profile-image"
                        src={userData?.image}
                        sx={{ width: 40, height: 40 }}
                      />
                    ) : userData?.name ? (
                      <Avatar
                        {...stringAvatar(userData?.name as string)}
                        sx={{
                          width: 40,
                          height: 40,
                          bgcolor: '#45B755',
                          fontSize: '19px',
                          text: 'fff',
                          fontWeight: 'bold',
                        }}
                      // color="#45B755"
                      // onClick={(e) => {
                      //   handleClick(e)
                      // }}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(userData?.role as string)}
                        sx={{
                          width: 40,
                          height: 40,
                          bgcolor: '#45B755',
                          fontSize: '19px',
                          text: 'fff',
                          fontWeight: 'bold',
                        }}
                      // color="#45B755"
                      // onClick={(e) => {
                      //   handleClick(e)
                      // }}
                      />
                    )}
                  </>
                )}
                <div className="px-3">
                  {userData?.name && (
                    <div className="font-bold text-19">{userData?.name}</div>
                  )}
                  {userData?.role && (
                    <div
                      className={`${userData?.name
                        ? 'font-medium text-[12px]'
                        : 'font-bold text-19'
                        }`}
                    >
                      {userData?.role}{' '}
                      {userData?.role === 'District Admin' && (
                        <>{userData?.district}</>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="hidden items-center md:me-2  md:block">
              {/* <div className=" w-9 h-9 flex items-center justify-center">
              <ThemeSwitcher />
            </div> */}
              <Button onClick={() => handleLogout()} label={'Logout'} />
            </div>

            <div className="block items-center me-1 md:hidden">
              <Icons name="logout" onClick={() => handleLogout()} />
            </div>

            {/* <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={() => setAuthenticated(false)}>Profile</MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          </Menu> */}
          </>
        ) : (
          <>
            <div className="flex h-16 relative">
              <img src="/header-logo.png" alt="ayush logo" />
            </div>
          </>
        )}
      </header>
      {profile && (
        <div className="w-[500px]">
          <DialogModal
            isOpen={true}
            className="w-2/5"
            body={
              <>
                <div className="flex justify-center mb-3 text-[24px] leading-normal font-bold">
                  {/* <Button
                  primary={true}
                  outlined
                  className="w-[40%] text-[24px] "
                  // outlined={true}
                  label="PROFILE"
                /> */}
                  PROFILE

                </div>
                <div className="my-5 mx-5">
                  <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
                    <FormProvider {...methods}>
                      <GeneralInfoForm edit={true} />
                    </FormProvider>
                    <Button onClick={handleReset} label={'Reset Password'} outlined={true} />

                  </div>
                </div>
              </>
            }
            onClose={handleClose}
          // title={'Add New Pharmacist'}
          />
        </div>
      )}
      {reset && (
        <div className="w-[500px]">
          <DialogModal
            isOpen={true}
            className="w-1/4"
            body={
              <>
                <div className="flex justify-center mb-3 text-[20px] leading-normal font-bold">
                  {/* <Button
                  primary={true}
                  outlined
                  className="w-[40%] text-[24px] "
                  // outlined={true}
                  label="PROFILE"
                /> */}
                  RESET PASSWORD

                </div>
                <div className="my-5 mx-2">
                  <div className="grid md:grid-cols-1 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
                    <FormProvider {...methodsReset}>
                      <GeneralResetForm edit={true} />
                    </FormProvider>
                  </div>
                  <Button
                      onClick={methodsReset.handleSubmit(handleSubmit)}
                      label={'Submit'}
                      className="mt-5"
                      size='xs'
                    />
                </div>
              </>
            }
            onClose={handleCloseReset}
          // title={'Add New Pharmacist'}
          />
        </div>
      )}
    </>
  )
}
