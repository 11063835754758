import FormBuilder from '../../../../../components/app/formBuilder'

type Props = {
  edit?: boolean
  index?: number
  medData?: any
  manuData?: any
}

const CommonForm = ({ edit, index, medData, manuData }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name: `institution_stocks[${index}].${name}`,
    label,
    id: `${id}-${index}`,
    type,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })

  const formBuilderProps = [
    {
      ...textField(
        'manufacturer_name',
        'Manufacturer Name',
        'manufacturer_name',
        'custom_select',
        true
      ),
      data: manuData,
      placeholder: 'Choose Manufacturer',
    },
    {
      ...textField(
        'medicine_name',
        'Medicine Name',
        'medicine_name',
        'custom_search_select',
        true,
        {
          data: medData?.medicines.map((medicine: any) => ({
            name: medicine.medicine_name,
            id: medicine.id.toString(),
          })),
        }
      ),
      placeholder: 'Choose Medicine Name',
    },
    {
      ...textField(
        'unit_of_packing',
        'Unit of Packing',
        'unit_of_packing',
        'custom_select',
        true
      ),
      placeholder: 'Choose unit of Packing',
      data: Array.from(
        new Set(
          medData?.medicines.map(
            (medicine: { unit_of_packing: string }) => medicine.unit_of_packing
          ) // Specify type
        )
      ).map((unit: any, index: number) => ({
        name: unit,
        id: index.toString(),
      })),
    },
    {
      ...textField(
        'batch_number',
        'Batch No',
        'batch_number',
        'custom_search_select',
        true
      ),
      placeholder: 'Choose Batch No',
      data: Array.from(
        new Set(
          medData?.medicines.map(
            (medicine: { batch_number: string }) => medicine.batch_number
          )
        )
      ).map((batch: any, index: number) => ({
        name: batch,
        id: index.toString(),
      })),
    },
    {
      ...textField('quantity', 'Quantity', 'quantity', 'number', true),
    },
    {
      ...textField('unit', 'Unit', 'unit', 'text', false),
    },
    {
      ...textField('invoice_no', 'Invoice Number', 'invoice_no', 'text', false),
    },
    {
      ...textField(
        'invoice_date',
        'Invoice Date',
        'invoice_date',
        'date',
        false
      ),
    },
    {
      ...textField('speciality', 'Speciality', 'speciality', 'text', false),
    },
    {
      ...textField('fund_id', 'Fund Used', 'fund_id', 'custom_select', true),
      placeholder: 'Choose Fund',
      data: medData?.funds,
      desc: medData?.funds.name,
      descId: medData?.funds.id,
    },
  ]

  return <FormBuilder data={formBuilderProps} edit={edit} />
}

export default CommonForm
