import { z } from 'zod'

export const formInstanceSchema = z.object({
  manufacturer_name: z
    .string({ invalid_type_error: 'Manufacturer Name is required.' })
    .min(1, { message: 'Manufacturer Name is required.' }),
  medicine_name: z
    .string({ invalid_type_error: 'Medicine Name is required.' })
    .min(1, { message: 'Medicine Name is required.' }),
  unit_of_packing: z
    .string({ invalid_type_error: 'Unit of Packing is required.' })
    .min(1, { message: 'Unit of Packing is required.' }),
  batch_number: z
    .string({ invalid_type_error: 'Batch is required.' })
    .min(1, { message: 'Batch is required.' }),
  quantity: z.preprocess((val) => Number(val), z.number().nonnegative()),
  // quantity: z.number().min(1, { message: 'Quantity is required.' }),
  unit: z.string().optional(),
  invoice_no: z.string().optional(),
  invoice_date: z.date().optional().default(new Date()),
  speciality: z.string().optional(),
  fund_id: z.string().optional(),
})

export const formSchema = z.object({
  institution_stocks: z.array(formInstanceSchema),
})

export type CreateSchema = z.infer<typeof formSchema>
