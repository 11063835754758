// import FormBuilder from '../../../components/app/formBuilder/index'
import FormBuilder from '../../../../../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const EditForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('batch_number', 'Batch.No', 'batch_number', 'text', false),
      disabled: true,
    },
    {
      ...textField(
        'medicine_name',
        'Medicine Name',
        'medicine_name',
        'text',
        false
      ),
      disabled: true,
    },
    {
      ...textField(
        'unit_of_packing',
        'Unit of Packing (ml)',
        'unit_of_packing',
        'text',
        false
      ),
      disabled: true,
    },
    {
      ...textField(
        'primary_stock',
        'Available Stock',
        'primary_stock',
        'text',
        false
      ),
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default EditForm
