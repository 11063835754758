import React from 'react'
import Button from '../buttons/Button'
// import { Button, Icon } from '..'
import Icons from '../icons'
// import ManagedList from './ManagedList'
import Tooltip from '@mui/material/Tooltip'
import Select from 'react-select'

type BasicDataProps = {
  title: string | number
  icon?: string
}

// type OptionsProps = {
//   value: string
//   label?: string
// }
// const options: OptionsProps[] = [
//   { value: 'Ayurveda', label: 'Ayurveda' },
//   { value: 'Homeo', label: 'Homeo' },
//   { value: 'Unani', label: 'Unani' },
//   { value: 'Yoga', label: 'Yoga' },
//   { value: 'Siddha', label: 'Siddha' },
// ]
// type ActionProps = {
//   id: number
//   label: string
//   icon?: string
//   isOutlined?: boolean
// }

type DetailTileProps = {
  data: BasicDataProps
  onActionClick?: () => void
  // onHandleExport?: () => void
  handleUploadFile?: (file: any) => void
  actionProps?: any
  actionTitle?: any
  // showManagedList?: boolean
  // groupData?: any
  // groupValue?: any
  // setGroupNameCode?: any
  setCustomType?: any
  setCustomSearch?: any
  setCustomQuantity?: any
  manufactures?: any[]
  medicines?: any[]
  customQuantity?: any
  customSearch?: any
}

const CustomisedListingHeader: React.FC<DetailTileProps> = ({
  data,
  onActionClick,
  // onHandleExport,
  actionProps,
  actionTitle,
  // showManagedList = false,
  // groupData = [],
  // groupValue,
  // setGroupNameCode,
  handleUploadFile,
  setCustomSearch,
  customSearch,
  setCustomType,
  setCustomQuantity,
  manufactures,
  customQuantity,
  medicines,
}) => {
  const handleFileChange = (event: any) => {
    // Access the uploaded file using event.target.files[0]
    // console.log('1', event)
    const uploadedFile = event.target.files[0]
    // Perform any additional logic with the file
    // // console.log('Uploaded file:', uploadedFile)
    // console.log('2')
    handleUploadFile?.(uploadedFile)
    // console.log('3')
  }
  const handleButtonClick = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById('file-upload')
    if (fileInput) {
      fileInput.click()
    }
  }
  const handleDownload = () => {
    const excelFilePath = '/Hospital In Charge Purchase medicine.xlsx'
    const link = document.createElement('a')
    link.download = 'Hospital In Charge Purchase medicine.xlsx'
    link.href = excelFilePath
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  // console.log(onHandleExport)
  // console.log(options)

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 100)) {
      // Update the state with the valid quantity
      setCustomQuantity(value)
    }
  }

  const medicineOptions = medicines?.map((item: any) => ({
    value: item.id,
    label: item.namewithqn,
  }))

  return (
    <div className="flex justify-between flex-wrap gap-3 items-center border-formBorder">
      <div className="flex gap-6">
        {data.icon && data.title && (
          <div className="flex items-center flex-wrap gap-5 text-[#222] ">
            <div className="flex items-center gap-3">
              {data?.icon && (
                <div className="w-10 h-10 bg-primary flex  items-center justify-center rounded-[4px]">
                  <Icons className="iconWhite" name={data?.icon} />
                </div>
              )}
              <div className="">
                <div className="font-bold text-lg text-primaryText">
                  {data.title}
                </div>
              </div>
            </div>
          </div>
        )}
        {setCustomType && (
          <div className="table-header-select border rounded-lg h-auto">
            <select
              name="institution"
              id="institutions"
              className="py-1 px-2 bg-white w-full h-full"
              onChange={(e) => setCustomType(e.target.value)}
            >
              {' '}
              <option value="select">Select Manufacturer</option>
              {manufactures?.map((item: any, i: number) => (
                <>
                  {item.name && item.name !== '' && (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
        )}
        {setCustomSearch && (
          <div className="table-header-select border rounded-lg h-auto mr-2">
            <Select
              value={medicineOptions?.find(
                (option: any) => option.value === customSearch
              )}
              onChange={(selectedOption) =>
                setCustomSearch(selectedOption?.value)
              }
              // onInputChange={(inputValue) => setCustomSearch(inputValue)}
              options={medicineOptions}
              className="rounded-xl w-[220px] h-auto"
              noOptionsMessage={() => 'No Medicines Found'}
              placeholder="Enter Medicine Name"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    state.isFocused || state?.menuIsOpen
                      ? '#e5e7eb'
                      : '#e5e7eb',
                  borderRadius: '.5rem',
                }),
              }}
            />

            {/* <select
              name="institution"
              id="institutions"
              // disabled = {disabled}
              className="py-1 px-2 bg-white w-full h-full"
              value={customSearch}
              onChange={(e) => setCustomSearch(e.target.value)}
            >
              <option value="select">Select a Medicine (Quantity)</option>
              {medicines?.map((item: any, i: number) => (
                <>
                  {item.namewithqn && item.namewithqn !== '' && (
                    <option key={i} value={item.id}>
                      {item.namewithqn}
                    </option>
                  )}
                </>
              ))}
            </select> */}
          </div>
        )}
        {/* <div className="border rounded-lg px-3 py-1 flex items-center h-auto table-header-input">
          <img
            src="/search.png"
            alt="search here"
            className="w-auto h-[15px]"
          />
          <input
            type="text"
            placeholder="Search Medicine Name"
            name="search"
            className=" px-2"
            onChange={(e) => setCustomSearch(e.target.value)}
          />
        </div> */}
        {/* {showManagedList && (
          <div className="flex items-center justify-start">
            <ManagedList
              groupData={groupData}
              selection={groupData?.findIndex(
                (item: any) => item.code === groupValue
              )}
              setGroupNameCode={setGroupNameCode}
            />
          </div>
        )} */}
        {setCustomQuantity && (
          <div className="border rounded-lg px-3 py-1.5 flex items-center h-auto table-header-input">
            <input
              type="number"
              placeholder="Enter Medicine Quantity"
              name="search"
              className=" px-2 h-full w-[150px] 2xl:w-[200px] noBorder"
              value={customQuantity}
              // onChange={(e) => setCustomQuantity(e.target.value)}
              onChange={handleQuantityChange}
            />
          </div>
        )}

        <div className="font-normal leading-4 items-center flex align-middle text-SidenavFontColor">
          OR
        </div>
        {!actionProps?.hideExport && (
          <Tooltip
            title={
              <div
                className="cursor-pointer text-m font-normal flex items-end px-2 text-white underline"
                onClick={handleDownload}
              >
                Download Excel Format
              </div>
            }
            placement="top"
            className="bg-white"
          >
            <span data-testid="dfssd" className="text-center text-white">
              <div>
                <label htmlFor="file-upload">
                  <Button
                    label="Upload Excel"
                    icon="upload"
                    outlined={true}
                    // onClick={onHandleExport}
                    onClick={handleButtonClick}
                    className="w-[155px]"
                  />
                </label>
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    // console.log('this is the function');
                    handleFileChange(e)
                  }}
                />
              </div>
            </span>
          </Tooltip>
        )}
        {actionProps?.actionCancel && (
          <Button
            label="Cancel"
            outlined={true}
            primary={false}
            onClick={() => actionProps.actionCancel()}
          />
        )}
      </div>

      <div className="flex gap-2">
        {onActionClick && (
          <div>
            <Button
              label={actionTitle}
              icon={actionProps?.actionTitle ? '' : ''}
              onClick={onActionClick}
              disabled={actionProps?.disableSubmit}
              className="w-[177px]"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomisedListingHeader
