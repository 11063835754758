// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../../../../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const GeneralInfoForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('id', 'Unique ID', 'name', 'text', false),
      disabled: true,
    },
    { ...textField('op', 'OP Number', 'op', 'number', false), disabled: true },
    { ...textField('name', 'Name', 'name', 'text', false), disabled: true },

    {
      ...textField('gender', 'Gender', 'gender', 'text', false),
      disabled: true,
    },
    // {
    //   ...textField('dob', 'Date of birth', 'dob', 'text', false),
    //   disabled: true,
    // },
    {
      ...textField('age', 'Age', 'age', 'text', false),
      disabled: true,
    },
    {
      ...textField('number', 'Phone Number', 'number', 'number', false),
      disabled: true,
    },
    {
      ...textField('district', 'District', 'district', 'text', false),
      disabled: true,
    },
    {
      ...textField('address', 'Address', 'address', 'text', false),
      disabled: true,
    },
    {
      ...textField(
        'specialization',
        'Specialization',
        'specialization',
        'text',
        false
      ),
      disabled: true,
    },
    {
      ...textField('doctor', 'Doctor', 'doctor', 'text', false),
      disabled: true,
    },
    {
      ...textField(
        'aadhar_number',
        'Aadhar number',
        'aadhar_number',
        'text',
        false
      ),
      disabled: true,
    },
    {
      ...textField('remarks', 'Remarks', 'remarks', 'text', false),
      disabled: true,
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default GeneralInfoForm
