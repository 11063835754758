import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Medicine Name is required.' })
    .min(1, { message: 'Medicine Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  unit_of_packing: z
    .string({ invalid_type_error: 'Unit of Packing is required.' })
    .min(1, { message: 'Unit of Packing is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  // batch_number: z
  //   .string()
  //   .nullable()
  //   .transform((value) => (value === null ? '' : value))
  //   .refine(
  //     (value) => {
  //       return (
  //         (/^\d+(?:\.\d{0,10})?$/.test(value) &&
  //           value.length <= 10 &&
  //           parseFloat(value) !== 0) ||
  //         value === ''
  //       )
  //     },
  //     {
  //       message: 'Enter a valid Batch No',
  //     }
  //   ),
  batch_number: z.string().nullable(),
  stock: z.string().nullable(),

  // stock: z
  //   .string()
  //   .nullable()
  //   .transform((value) => (value === null ? '' : value))
  //   .refine(
  //     (value) => {
  //       return (
  //         (/^\d+(?:\.\d{0,10})?$/.test(value) &&
  //           value.length <= 10 &&
  //           parseFloat(value) !== 0) ||
  //         value === ''
  //       )
  //     },
  //     {
  //       message: '',
  //     }
  //   ),
})

export type CreateSchema = z.infer<typeof formSchema>
